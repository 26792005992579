// extracted by mini-css-extract-plugin
export const root = "project-module--root--1DtzU";
export const title = "project-module--title--2ReKs typography-module--responsiveTitle1--2aak3";
export const grid = "project-module--grid--tznxb";
export const mainContent = "project-module--mainContent--3KUnP";
export const metaContent = "project-module--metaContent--3YJwW";
export const gallery = "project-module--gallery--OQeg3";
export const gallerySingle = "project-module--gallerySingle--teuG_";
export const publishedAt = "project-module--publishedAt--3o_YC typography-module--small--2jpCI";
export const mainImage = "project-module--mainImage--3QLz_";
export const categories = "project-module--categories--KtvHh";
export const categoriesHeadline = "project-module--categoriesHeadline--2wvbx typography-module--base--2DtHq";
export const relatedProjects = "project-module--relatedProjects--1iGpA";
export const relatedProjectsHeadline = "project-module--relatedProjectsHeadline--2Yf9w typography-module--base--2DtHq";
import { format, distanceInWords, differenceInDays } from "date-fns";
import React from "react";
import { Link } from "gatsby";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";
import BlockText from "./block-text";
import Container from "./container";
import RoleList from "./role-list";
import Video from "./video"
import { Figure } from './figure'

import { responsiveTitle3, link } from './typography.module.css'
import { text, details } from './project-preview.module.css';
import * as styles from "./project.module.css";

function Project(props) {
  const { _rawBody, title, categories, mainImage, members, publishedAt, relatedProjects, _rawDescription, _rawVideo, _rawGallery, _rawAdditionalInfo, location } = props;
  console.log(mainImage)
  return (
    <article className={styles.root}>

      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            {/* <h1 className={styles.title}>{title}</h1> */}

            {_rawVideo ? <Video assetDocument={_rawVideo.asset} showControls={true} autoplay={true} /> : <>{props.mainImage && mainImage.asset && (
              <div className={styles.mainImage}>
                <Figure node={mainImage} />
              </div>
            )}</>}
          </div>
          <aside className={styles.metaContent}>
            {/* {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), "MMMM Do YYYY")}
              </div>
            )} */}
            <div className={cn(text, 'border-none flex md:w-1/2 mx-auto')}>
              <h3 className={cn(responsiveTitle3, title)}>{title}</h3>
              <ul className={cn(details, '')}>
                {_rawDescription && <li><h4>Description</h4><div><BlockText blocks={_rawDescription} /></div></li>}
                {categories?.length > 0 && <li><h4>Credits</h4><div>{categories.map((category, index) => <React.Fragment>{category.title}{index < categories.length - 1 && ", "}</React.Fragment>)}</div></li>}
                {location && <li><h4>Location</h4><div>{location}</div></li>}
                {_rawAdditionalInfo && <li><h4>Details</h4><div><BlockText blocks={_rawAdditionalInfo} /></div></li>}
                {_rawBody && <li><h4>Summary</h4><div>{_rawBody && <BlockContent blocks={_rawBody || []} />}<br /></div></li>}
              </ul>
            </div>
            <div className={_rawGallery.length > 1 ? styles.gallery : styles.gallerySingle}>
              {_rawGallery?.map((image) => (<div key={image.asset.id}><Figure node={image} /></div>))}
            </div>
            {/* {categories && categories.length > 0 && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Categories</h3>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )} */}
            {relatedProjects && relatedProjects.length > 0 && (
              <div className={styles.relatedProjects}>
                <h3 className={styles.relatedProjectsHeadline}>Related projects</h3>
                <ul>
                  {relatedProjects.map(project => (
                    <li key={`related_${project._id}`}>
                      {project.slug ? (
                        <Link to={`/project/${project.slug.current}`}>{project.title}</Link>
                      ) : (
                        <span>{project.title}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </aside>
        </div>
      </Container >
    </article >
  );
}

export default Project;
